<template>
    <OperationMould :opId="opId" :type="type"></OperationMould>
</template>

<script>
    import OperationMould from "../../../components/evaluation/OperationMould";

    export default {
        name: "OperationPaperDetail",
        components: {OperationMould},
        data() {
            return {
                opId: Number(this.$route.query.op_id) || null,
                type: this.$route.query.type || null,
            }
        }
    }
</script>

<style scoped>

</style>